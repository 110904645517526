import React, { useState } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

const TextEditor = ({ data, setData, defaultData, fullscreen, className = "" }) => {
  const [IsRawMode, setIsRawMode] = useState(false)

  const handleToggleRawMode = () => {
    setIsRawMode(!IsRawMode)
  }

  const handleChange = (html) => {
    setData(html)
  }

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
    ],
  }

  const formats = ["header", "bold", "italic", "underline", "blockquote", "list", "bullet", "link", "image"]

  return (
    <div className={`relative ${className}`}>
      <button
        className={`flex justify-end sticky ml-auto h-0 ${
          fullscreen ? "mr-28 top-9" : "mr-20 top-8"
        } z-10 whitespace-nowrap text-[#98a2b3]`}
        onClick={handleToggleRawMode}
      >
        {IsRawMode ? "Geri Dön" : "HTML"}
      </button>
      {IsRawMode ? (
        <div className="pt-[94px] h-[594px] mb-4">
          <textarea
            className="w-full h-full"
            value={data}
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>
      ) : (
        <ReactQuill
          theme="snow"
          className={`${!fullscreen ? "-mt-8" : ""}`}
          value={data || defaultData}
          onChange={handleChange}
          modules={modules}
          formats={formats}
          placeholder="Burada yazmaya başlayın..."
        />
      )}
    </div>
  )
}

export default TextEditor
