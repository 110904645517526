import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

import { TitleH1 } from "components"
import Icon from "components/Icon"
import Header from "components/Headers/MainHeader"
import InfoBox from "components/Contracts/InfoBox"
import EditorBtnGroup from "components/Contracts/EditorBtnGroup"
import ContractsSkeleton from "skeletons/Conracts"
import TextEditor from "components/TextEditor"

import { agreementRequested } from "redux/actions/getAgreement"
import { saveAgreementRequested } from "redux/actions/saveAgreement"

const Editor = ({ isLogged }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let { contractId } = useParams()

  const agreementData = useSelector((state) => state.getAgreement.list?.agreements)
  const agreementLoading = useSelector((state) => state.getAgreement.loading)
  const saveAgreementLoading = useSelector((state) => state.saveAgreement.loading)

  const [Flag, setFlag] = useState(false)
  const [Visibility, setVisibility] = useState({
    info: true,
    default: false,
  })
  const [OpenModal, setOpenModal] = useState(false)
  const [FilteredAgreementData, setfilteredgAgreementData] = useState()
  const [Content, setContents] = useState({})
  const [Fullscreen, setFullscreen] = useState(false)

  const rejectButtonClick = () => {
    setOpenModal(false)
  }

  const saveAndPublish = () => {
    dispatch(
      saveAgreementRequested({
        Agreement: {
          AgreementId: FilteredAgreementData?.agreementId,
          TypeValue: Content?.typeValue,
          Body: Content?.contentValues,
        },
      }),
    )
  }

  const handleChange = (e) => {
    if (!Flag) {
      setFlag(true)
      setVisibility((prevVisibility) => ({
        ...prevVisibility,
        default: false,
      }))
    }
    setContents((prev) => ({
      ...prev,
      contentValues: e,
    }))
  }

  const handleDefaultButtonClick = () => {
    setFlag(false)
    setVisibility((prevVisibility) => ({
      ...prevVisibility,
      info: false,
      default: true,
    }))
    setContents((prev) => ({
      ...prev,
      contentValues: FilteredAgreementData?.body,
    }))
  }

  useEffect(() => {
    dispatch(agreementRequested())
  }, [dispatch])

  useEffect(() => {
    if (agreementData) {
      const filteredAgreementData = agreementData?.find((item) => item.typeValue === contractId * 1)
      setfilteredgAgreementData(filteredAgreementData)
      setContents(filteredAgreementData)
    }
  }, [agreementData, contractId])

  useEffect(() => {
    if (saveAgreementLoading) {
      setOpenModal(false)
      setVisibility((prevVisibility) => ({
        ...prevVisibility,
        info: false,
        default: false,
      }))

      navigate(`/contracts/view/${contractId}`)
      sessionStorage.setItem("saveAgreementSuccess", true)
    }
  }, [FilteredAgreementData?.typeValue, contractId, navigate, saveAgreementLoading])

  return (
    <>
      <Header isLogged={isLogged} />
      {agreementLoading && <ContractsSkeleton />}
      {!agreementLoading && (
        <main>
          <section className="p-10 my-0 mb-20 mx-auto max-w-[1440px]">
            <button
              className="flex items-center gap-2 cursor-pointer mb-6"
              onClick={() => {
                navigate(`/contracts`)
                sessionStorage.setItem("saveAgreementSuccess", false)
              }}
            >
              <Icon
                name="arrow-left"
                size="16"
                color="#667085"
              />
              <span className="text-gray-500">Sözleşmelere dön</span>
            </button>
            <div className="page-top-area flex justify-between items-center mb-6 text-right">
              <div className="page-title">
                <TitleH1 className="text-3xl font-bold">{FilteredAgreementData?.type}</TitleH1>
              </div>
            </div>
            <div className="editor-area">
              {Visibility.info && (
                <InfoBox
                  isVisible={Visibility.info}
                  onClose={() =>
                    setVisibility((prevVisibility) => ({
                      ...prevVisibility,
                      info: false,
                    }))
                  }
                  icon="info"
                  bgColor={"#F5FAFF"}
                  iconColor={"#2E90FA"}
                  iconBgColor={"#D1E9FF"}
                  title="Bilgilendirme"
                  content="Sözleşmeler işletmeniz ve müşterilerinizin yasal haklarını koruyan metinlerdir. Yaptığınız değişikliklerin web sitenizi ziyaret eden müşterilere gösterileceğini unutmayın!"
                />
              )}
              {Visibility.default && (
                <InfoBox
                  isVisible={Visibility.default}
                  onClose={() =>
                    setVisibility((prevVisibility) => ({
                      ...prevVisibility,
                      default: false,
                    }))
                  }
                  icon="rotate-ccw"
                  bgColor={"#F5FAFF"}
                  iconColor={"#2E90FA"}
                  iconBgColor={"#D1E9FF"}
                  title="Varsayılan metine geri dönüldü."
                  content="Yaptığınız değişiklikler sıfırlandı. Tekrar düzenleyebilir veya mevcut ayarlarınızı kaydedebilirsiniz."
                />
              )}
              <p className="text-gray-800 font-medium text-sm mb-4">Sözleşme Metni</p>

              {Fullscreen ? (
                <div className="bg-[#344054b3] fixed inset-0 z-[60] flex justify-center items-center">
                  <div className="bg-white max-h-[87%] relative w-5/6 rounded-lg overflow-auto">
                    <div className="flex justify-end sticky ml-auto mr-3 top-8 z-10 h-0">
                      <Icon
                        onClick={() => setFullscreen(!Fullscreen)}
                        className={"cursor-pointer"}
                        name="minimize-2"
                        size="25"
                        color="#98A2B3"
                      />
                    </div>
                    <TextEditor
                      data={Flag ? Content?.contentValues : FilteredAgreementData?.body}
                      setData={handleChange}
                      fullscreen={Fullscreen}
                    />
                    <div className={`button-group sticky w-full bottom-0 flex flex-row justify-end gap-4 mt-6 p-4 bg-white ${!Fullscreen ? "pb-5" : ""}`}>
                      <EditorBtnGroup
                        Flag={Flag}
                        Visibility={Visibility}
                        OpenModal={OpenModal}
                        setOpenModal={setOpenModal}
                        rejectButtonClick={rejectButtonClick}
                        saveAndPublish={saveAndPublish}
                        saveAgreementLoading={saveAgreementLoading}
                        handleDefaultButtonClick={handleDefaultButtonClick}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="relative">
                  <div className="flex justify-end sticky ml-auto mr-3 top-8 z-10 h-0">
                    <Icon
                      onClick={() => setFullscreen(!Fullscreen)}
                      className={"cursor-pointer"}
                      name="maximize-2"
                      size="25"
                      color="#98A2B3"
                    />
                  </div>
                  <TextEditor
                    defaultData={FilteredAgreementData?.body}
                    data={Flag ? Content?.contentValues : FilteredAgreementData?.body}
                    setData={handleChange}
                  />
                  <div className="flex justify-between mt-6 pb-24">
                    <EditorBtnGroup
                      Flag={Flag}
                      Visibility={Visibility}
                      OpenModal={OpenModal}
                      setOpenModal={setOpenModal}
                      rejectButtonClick={rejectButtonClick}
                      saveAndPublish={saveAndPublish}
                      saveAgreementLoading={saveAgreementLoading}
                      handleDefaultButtonClick={handleDefaultButtonClick}
                    />
                  </div>
                </div>
              )}
            </div>
          </section>
        </main>
      )}
    </>
  )
}

Editor.propTypes = {
  data: PropTypes.object,
  setContents: PropTypes.func,
  saveAndPublish: PropTypes.func,
}

export default Editor
